import Avatar from "@mui/material/Avatar";
import imageCompression from "browser-image-compression";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import addPhoto from "../images/addPhoto.svg";
import Design from "./UpdateImage.module.css";

function UpdateImage(props) {
  const { onImageSelected, url } = props;
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const fileInputRef = useRef(null);

  const getImage = async () => {
    const imageUrl = `${BASE_URL}${url}`;
    try {
      const res = await fetch(imageUrl, {
        headers: {
          Authorization: props.authToken,
        },
      });
      const reader = await res.body.getReader();
      let chunks = [];
      reader.read().then(function processText({ done, value }) {
        if (done) {
          const blob = new Blob([chunks]);
          setPreview(URL.createObjectURL(blob));
          return;
        }
        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;
        return reader.read().then(processText);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (url) {
      getImage();
    }
  }, [url]);

  const handleImageUpload = async () => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(image, options);
      onImageSelected(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
      handleImageUpload();
    } else {
      setPreview(null);
    }
  }, [image]);

  return (
    <div className={Design.editPhoto}>
      <div className={Design.uploadedImageSection}>
        <div className={Design.uploadImage}>
          <Avatar src={preview} sx={{ width: "125px", height: "125px" }} />
        </div>

        <div
          className={Design.photoEditBtn}
          onClick={(event) => {
            event.preventDefault();
            fileInputRef.current.click();
          }}
        >
          <img src={addPhoto} alt="addPhoto" />
          <p className={Design.addPhotoText}>Place Image</p>
        </div>
      </div>
      <input
        required
        accept=".png, .jpg, .jpeg"
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={(event) => {
          const file = event.target.files[0];
          if (file) {
            setImage(file);
          } else {
            setImage(null);
          }
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authReducer.authToken,
  };
};

export default connect(mapStateToProps, null)(UpdateImage);
