import Person from "components/Person";
import expandLess from "images/expandLess.svg";
import expandMore from "images/expandMore.svg";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { UPDATE_USERDATA } from "store/actions/selectUserActions";
import { getProfileImageFromGalleryImages } from "utils/functions/formatters";
import LongMenu from "../LongMenu";
import Design from "./MobileView.module.css";

function UsersMobileView(props) {
  const { user } = props;
  let options = [];
  const location = useLocation();
  const path = location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const subscriptionIsActive = user.subscription ? user.subscription.isActive : null;

  const subscriptionExpiringOn = user.subscription ? user.subscription.expiringOn : null;
  var endDate = moment(subscriptionExpiringOn).format("DD/MM/YYYY");
  var today = moment().format("DD/MM/YYYY");
  endDate = new Date(endDate.split("/")[2], endDate.split("/")[1] - 1, endDate.split("/")[0]);
  today = new Date(today.split("/")[2], today.split("/")[1] - 1, today.split("/")[0]);
  const timeDiff = Math.abs(today.getTime() - endDate.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  let statusText;

  if (path.includes("/subscribe")) {
    statusText = diffDays <= 10 ? `${diffDays} days left` : null;
  } else if (path.includes("/Pending")) {
    statusText = diffDays >= 0 ? "active" : null;
  }

  if (user.isVerified && diffDays >= 0) {
    options = ["Share", "Deactivate", "Delete"];
  } else if (user.isVerified && diffDays < 0) {
    options = ["Deactivate", "Delete"];
  } else if (!user.isVerified && diffDays < 0) {
    options = ["Deactivate", "Delete"];
  } else if (!user.isVerified) {
    options = ["Reject", "Approve"];
  }

  const getSelectedUser = () => {
    props.onPassSelectedUser(user);
  };

  const isSelected = props.selectedUsers.find((selectedUser) => selectedUser.referenceNumber === user.referenceNumber) !== undefined;

  return (
    <div>
      <div className={Design.container}>
        {diffDays >= 0 && path.includes("/subscribe") ? (
          <input type="checkbox" className={Design.checkbox} readOnly={true} onClick={getSelectedUser} checked={isSelected}></input>
        ) : null}
        <Person
          id={user.id}
          name={user.name}
          referenceNumber={user.referenceNumber}
          profileImageUrl={getProfileImageFromGalleryImages(user.galleryImages)}
          status={statusText}
        />
        <LongMenu options={options} user={user} />
        <div className={Design.expandIcons}>
          {isOpen ? <img src={expandLess} alt="expandLess" onClick={toggleAccordion} /> : <img src={expandMore} alt="expandMore" onClick={toggleAccordion} />}
        </div>
      </div>
      {/* accordion */}
      {isOpen ? (
        <div className={Design.accordion}>
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Details</p>
            <p className={Design.accordionValue}>
              {user.gender} {user.age} yrs, {user.hometown}
            </p>
          </div>
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Phone</p>
            <p className={Design.accordionValue}>
              {user.countryCode} {user.phoneNumber}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onPassSelectedUser: (selectedUser) => dispatch({ type: UPDATE_USERDATA, selectedUser }),
  };
};

const mapStateToProps = (state) => {
  return {
    selectedUsers: state.selectUserReducer.selectedUsers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersMobileView);
