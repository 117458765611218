import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getImageUrl } from "utils/functions/formatters";
import Design from "./ImageModel.module.css";
import loading from "./loading.gif";

function GalleryCarouselImage(props) {
  const { url } = props;
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getImage = async () => {
    const imageUrl = getImageUrl(url);
    try {
      const res = await fetch(imageUrl, {
        headers: {
          Authorization: props.authToken,
        },
      });
      if (res.ok) {
        const data = await res.json();
        setImage(data.url); // Use the URL from the JSON response
        setIsLoading(false);
      } else {
        console.error("Failed to fetch image:", res.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getImage();
  }, [url]);

  return (
    <span>
      {isLoading ? (
        <div className={Design.loadingScreen}>
          <img className={Design.loadingGIF} src={loading} alt="loading..." />
        </div>
      ) : (
        <img className={Design.carouselImages} src={image} alt="carousel" />
      )}
    </span>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authReducer.authToken,
  };
};

export default connect(mapStateToProps, null)(GalleryCarouselImage);
