import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Person from "components/Person";
import { getProfileImageFromGalleryImages } from "utils/functions/formatters";
import { dateFormatter } from "../utils/functions/dateFormatter";
import LongMenu from "./LongMenu";
import Design from "./Users.module.css";

export default function ReportedUsers(props) {
  const { user } = props;
  let options = ["Remove"];
  let date = `Reported on ${dateFormatter(user.updatedAt)}`;
  let reportedUserName = user.reportedUser ? user.reportedUser.name : null;
  let reportedUserReferenceNum = user.reportedUser ? user.reportedUser.referenceNumber : null;
  let reportedByUserName = user.reportedByUser ? user.reportedByUser.name : null;
  let reportedByUserReferenceNum = user.reportedByUser ? user.reportedByUser.referenceNumber : null;
  let reportedByProfilePic = user.reportedByUser ? user.reportedByUser.galleryImages : null;
  let reportedUserProfilePic = user.reportedUser ? user.reportedUser.galleryImages : null;

  return (
    <TableRow sx={{ maxHeight: 440 }}>
      <TableCell sx={{ border: "none" }}>
        <Person
          id={user.reportedUserId}
          name={reportedUserName}
          referenceNumber={reportedUserReferenceNum}
          profileImageUrl={getProfileImageFromGalleryImages(reportedByProfilePic)}
        />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <Person
          id={user.reportedByUserId}
          name={reportedByUserName}
          referenceNumber={reportedByUserReferenceNum}
          profileImageUrl={getProfileImageFromGalleryImages(reportedUserProfilePic)}
        />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <div>
          <p className={Design.profileDetails}>{user.reason}</p>
          <p className={Design.approvedDate}>{date}</p>
        </div>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <LongMenu options={options} user={user} />
      </TableCell>
    </TableRow>
  );
}
