import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Status from "components/Status";
import { getProfileImageFromGalleryImages } from "utils/functions/formatters";
import Person from "../components/Person";
import RequestIconBox from "../requests/RequestIconBox";

export default function EditRequest(props) {
  const { request } = props;
  let sign;
  if (request.status === "PENDING") {
    sign = "warning";
  } else {
    sign = "error";
  }

  return (
    <TableRow sx={{ maxHeight: 440 }} key={request.id}>
      <TableCell sx={{ border: "none" }}>
        <Person
          id={request.user.id}
          name={request.user.name}
          referenceNumber={request.user.referenceNumber}
          profileImageUrl={getProfileImageFromGalleryImages(request.user.galleryImages)}
          path={"update-profile"}
        />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <Status status={request.status} sign={sign} />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        {" "}
        {request.status === "PENDING" ? <RequestIconBox id={request.id} /> : request.comments ? request.comments : "No comments"}{" "}
      </TableCell>
    </TableRow>
  );
}
