import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import AdminBadge from "components/Badge";
import Person from "components/Person";
import { getProfileImageFromGalleryImages } from "utils/functions/formatters";
import { dateFormatter } from "../utils/functions/dateFormatter";
import LongMenu from "./LongMenu";
import Design from "./Users.module.css";

export default function DeactivatedUsers(props) {
  const { user } = props;

  let options = [];

  let feedback = user.feedback;
  let reason = feedback ? user.feedback.reason : "";
  let deactivatedBy = feedback ? user.feedback.deactivatedBy : "";
  let deactivatedDate = feedback ? user.feedback.createdAt : "";
  let date = `Deactivated on ${dateFormatter(deactivatedDate)}`;

  if (deactivatedBy === "admin") {
    deactivatedBy = "ADMIN";
  } else if (deactivatedBy === "user") {
    deactivatedBy = "USER";
  } else {
    deactivatedBy = "SUPER_ADMIN";
  }

  if (user.isVerified) {
    options = ["Reactivate", "Delete"];
  } else if (!user.isVerified) {
    options = ["Reactivate", "Reject", "Approve"];
  }

  return (
    <TableRow sx={{ maxHeight: 440 }}>
      <TableCell sx={{ border: "none" }}>
        <Person id={user.id} name={user.name} referenceNumber={user.referenceNumber} profileImageUrl={getProfileImageFromGalleryImages(user.galleryImages)} />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <div>
          <p className={Design.profileDetails}>{reason}</p>
          <p className={Design.approvedDate}>{date}</p>
        </div>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <AdminBadge type={deactivatedBy} />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <LongMenu options={options} user={user} />
      </TableCell>
    </TableRow>
  );
}
