import Person from "components/Person";
import { getProfileImageFromGalleryImages } from "utils/functions/formatters";
import RequestIconBox from "../requests/RequestIconBox";
import Design from "./EditRequests.module.css";

export default function EditRequestMobileview(props) {
  const { request } = props;

  let sign;
  if (request.status === "PENDING") {
    sign = "#F3B702";
  } else {
    sign = "#B72136";
  }

  return (
    <div className={Design.requestMobileView}>
      <div className={Design.requesterSection}>
        <Person
          id={request.user.id}
          name={request.user.name}
          referenceNumber={request.user.referenceNumber}
          profileImageUrl={getProfileImageFromGalleryImages(request.user.galleryImages)}
          path={"update-profile"}
        />
        <div style={{ backgroundColor: sign }} className={Design.statusDot}></div>
      </div>
      {request.status === "PENDING" ? <RequestIconBox id={request.id} /> : null}
    </div>
  );
}
