import { BASE_URL } from "utils/constants/ApplicationConstants";

export const getProfileImageFromGalleryImages = (galleryImages) => {
  console.log("Gallery images to get profile image", galleryImages);
  let profileImage = null;
  if (galleryImages && galleryImages.length > 0) {
    const profileImageIndex = galleryImages.findIndex((image) => image.type === "profile");
    if (profileImageIndex !== -1) {
      profileImage = galleryImages[profileImageIndex].url;
    } else {
      if (galleryImages && galleryImages.length > 0) {
        profileImage = galleryImages[0].url;
      }
    }
  }
  return profileImage;
};

export const getImageUrl = (url) => {
  // if url starts with /admin/ then return the url
  if (url && url.startsWith("admin/")) {
    return `${BASE_URL}${url}`;
  }
  if (url) {
    return `${BASE_URL}admin/${url}`;
  }
  return null;
};
