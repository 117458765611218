import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Person from "components/Person";
import moment from "moment";
import { smallDateFormatter } from "utils/functions/dateFormatter";
import LongMenu from "./LongMenu";
import Design from "./Users.module.css";

import { getProfileImageFromGalleryImages } from "utils/functions/formatters";
import activeUser from "../images/activeUser.svg";
import approvedUser from "../images/approvedUser.svg";
import subscribedUser from "../images/subscribedUser.svg";

function AllUsers(props) {
  const { user } = props;

  var endDate = user.subscription ? moment(user.subscription.expiringOn) : null;
  var today = moment();
  const remainingDays = endDate && endDate.isValid() ? endDate.diff(today, "days") + 1 : 0;
  const subscriptionIsActive = user.subscription.isActive === undefined ? null : user.subscription.isActive;
  const subscriptionDate = user.subscription.lastRenewedOn === undefined ? null : `Last payment on ${smallDateFormatter(user.subscription.lastRenewedOn)}`;

  let options = [];
  if (user.isVerified && user.isActive && remainingDays >= 0) {
    options = ["Share", "Deactivate", "Delete"];
  } else if (user.isVerified && !user.isActive && remainingDays >= 0) {
    options = ["Reactivate", "Delete"];
  } else if (user.isVerified && user.isActive && remainingDays < 0) {
    options = ["Deactivate", "Delete"];
  } else if (user.isVerified && !user.isActive && remainingDays < 0) {
    options = ["Reactivate", "Delete"];
  } else if (!user.isVerified) {
    options = ["Reject", "Approve"];
  }

  return (
    <TableRow sx={{ maxHeight: 440 }}>
      <TableCell sx={{ border: "none" }}>
        <div className={Design.checkcol}>
          <Person id={user.id} name={user.name} referenceNumber={user.referenceNumber} profileImageUrl={getProfileImageFromGalleryImages(user.galleryImages)} />
        </div>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <div>
          <p className={Design.profileDetails}>
            {user.gender} {user.age} yrs, {user.hometown}
          </p>
          <div className={Design.notifyStateIconsBar}>
            {user.isVerified ? <img src={approvedUser} alt="approvedUser" width={18} /> : null}
            {user.isActive ? <img src={activeUser} alt="activeUser" width={18} /> : null}
            {remainingDays >= 0 ? <img src={subscribedUser} alt="subscribedUser" width={18} /> : null}
          </div>
        </div>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <div className={Design.datesContainer}>
          <span>Registered on {smallDateFormatter(user.registeredOn)}</span>
          {subscriptionDate ? <span>{subscriptionDate}</span> : null}
        </div>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <LongMenu options={options} user={user} />
      </TableCell>
    </TableRow>
  );
}

export default AllUsers;
