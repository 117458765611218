// export const BASE_URL = `https://dev.gematrimony.com/`;
// export const BASE_URL_V2 = `https://dev.gematrimony.com/api/v2/`;

// export const BASE_URL= `https://d3d7-49-37-232-56.in.ngrok.io/`;

// export const BASE_URL = `http://localhost:4000/`;
// export const BASE_URL_V2 = `http://localhost:4000/api/v2/`;
export const BASE_URL = `https://server.gematrimony.com/`;
export const BASE_URL_V2 = `https://server.gematrimony.com/api/v2/`;

export const URL_RESPONSE = "URL_RESPONSE";
export const URL_INPUT = "URL_INPUT";
export const AUTOFILL_SEARCH_TIMER = 500;
export const LOADING_TIME = 2000;
