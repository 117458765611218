import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getImageUrl } from "utils/functions/formatters";

function RoundedAvatar(props) {
  const { url, size } = props;
  const [image, setImage] = useState("");

  const style = {
    width: size ?? "60px",
    height: size ?? "60px",
  };

  const getImage = async () => {
    const imageUrl = getImageUrl(url);
    try {
      const res = await fetch(imageUrl, {
        headers: {
          Authorization: props.authToken,
        },
      });
      if (res.ok) {
        const data = await res.json();
        setImage(data.url); // Use the URL from the JSON response
      } else {
        console.error("Failed to fetch image:", res.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImage();
  }, [url]);

  return <Avatar variant="rounded" sx={style} src={image} />;
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authReducer.authToken,
  };
};

export default connect(mapStateToProps, null)(RoundedAvatar);
