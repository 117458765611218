import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Status from "components/Status";
import { getProfileImageFromGalleryImages } from "utils/functions/formatters";
import Person from "../components/Person";
import RequestIconBox from "./RequestIconBox";

export default function Request(props) {
  const { request } = props;

  const requesterName = request.requesterDetails ? request.requesterDetails.name : null;
  const requestedName = request.requestedIdDetails ? request.requestedIdDetails.name : null;
  const requesterId = request.requesterDetails ? request.requesterDetails.referenceNumber : null;
  const requestedId = request.requestedIdDetails ? request.requestedIdDetails.referenceNumber : null;
  const requesterProfilePic = request.requesterDetails ? request.requesterDetails.galleryImages : null;
  const requestedProfilePic = request.requestedIdDetails ? request.requestedIdDetails.galleryImages : null;

  let sign;
  if (request.status === "PENDING") {
    sign = "warning";
  } else if (request.status === "APPROVED") {
    sign = "success";
  } else if (request.status === "REJECTED") {
    sign = "error";
  }

  return (
    <TableRow sx={{ maxHeight: 440 }} key={request.id}>
      <TableCell sx={{ border: "none" }}>
        <Person
          id={request.requester}
          name={requesterName}
          referenceNumber={requesterId}
          profileImageUrl={getProfileImageFromGalleryImages(requesterProfilePic)}
        />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <Person
          id={request.requestedId}
          name={requestedName}
          referenceNumber={requestedId}
          profileImageUrl={getProfileImageFromGalleryImages(requestedProfilePic)}
        />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <Status status={request.status} sign={sign} />
      </TableCell>
      <TableCell sx={{ border: "none" }}>{request.status === "PENDING" ? <RequestIconBox id={request.id} /> : null}</TableCell>
    </TableRow>
  );
}
